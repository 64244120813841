@import url(./animate.css);
@import url(./fonts.css);
/*Animations*/
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: Philosopher-Regular;
}

/*scrollabr*/

::-webkit-scrollbar {
    width: 10px;
  }
  
  /*track*/
  ::-webkit-scrollbar-track {
    background: rgb(194, 190, 190);
  }
  
  /*Handle*/
  ::-webkit-scrollbar-thumb {
    background-image: linear-gradient(
      to top,
      rgb(224, 48, 173),
      #2ec3c3,
      rgb(63, 155, 127),
      #37c0fb
    );
    border-radius: 5px;
  }
  
  /*Handle on hover*/
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  
.section-header{
    font-family: RadioCanada-VariableFont;
    text-align: center;
   
}

.price-list-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

}

#topBtn {
    display: none;
    position: fixed;
    bottom: 20px;
    right: 30px;
    z-index: 99;
    font-size: 18px;
    border: none;
    outline: none;
    background-color: #00bcc0;
    
    background-size: 100% 6em, 100% 6em, auto;
    background-position: top left, top left, top left;
    background-repeat: no-repeat, no-repeat, repeat;
    color: #fff;
    cursor: pointer;
    padding: 10px 10px;
    border-radius: 50px;
  }
  
  #topBtn:hover {
    background-color: rgb(224, 39, 145);
  }
  html {
    scroll-behavior: smooth;
  }