/*//////////////////////////////////////////////////////////////////
[ FONT ]*/

@font-face {
  font-family: Philosopher-Regular;
  src: url('../assets/fonts/Philosopher/Philosopher-Regular.ttf'); 
}

@font-face {
  font-family: Philosopher-Italic;
  src: url('../assets/fonts/Philosopher/Philosopher-Italic.ttf'); 
}

@font-face {
  font-family: Philosopher-Bold;
  src: url('../assets/fonts/Philosopher/Philosopher-Bold.ttf'); 
}

@font-face {
  font-family: Philosopher-BoldItalic;
  src: url('../assets/fonts/Philosopher/Philosopher-BoldItalic.ttf'); 
}


@font-face {
  font-family: Poppins-Regular;
  src: url('../assets/fonts/poppins/Poppins-Regular.ttf'); 
}

@font-face {
  font-family: Poppins-Medium;
  src: url('../assets/fonts/poppins/Poppins-Medium.ttf'); 
}

@font-face {
  font-family: Poppins-Bold;
  src: url('../assets/fonts/poppins/Poppins-Bold.ttf'); 
}

@font-face {
  font-family: Poppins-SemiBold;
  src: url('../assets/fonts/poppins/Poppins-SemiBold.ttf'); 
}
