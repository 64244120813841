@import url(./fonts.css);

.nav-bar-header {
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  padding: 0px 7%;
  display: flex;
  align-items: center;
  border: none;
  outline: none;
  background: #00BCC0;
  z-index: 1000;
}

.nav-bar-animated {
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

.nav-elements-container {
  display: flex;
  width: 100%;
}

.logo {
  padding: 12px;
  transform: translate(20%, -6%);
}

.company-name {
  width: 100%;
  padding: 0.5em;
  margin-top: 18px;
  line-height: 15px;
  font-size: 1.5em;
  font-weight: bolder;
  color: black;
  
}

.company-name .c-name1{
  color: #FFF;
}
.company-name .c-name2{
  color: rgb(224, 68, 173);
}
.company-name .c-name3{
  color: #37c0fb;
}
.nav-menu {
  display: flex;
  list-style: none;
  margin-left: 17em;
  justify-content: center;
  align-items: center;
}

.nav-menu li {
  position: relative;
  float: left;
}

.nav-link {
  font-family: Philosopher-Bold;
  font-size: 20px;
  margin: 5px;
  color: #fff;
  display: block;
  text-decoration: none;
  
}

.nav-link:active {
  color: #00BCC0;
}


.nav-bar-sub {
  width: 200px;
  margin-top: -10px;
  margin-bottom: -10px;
  border: none;
  outline: none;
  background-color: whitesmoke;
  
}


.nav-sub-link {
  font-family: Philosopher-Regular;
  font-size: large;
  width: 100%;
  color: #fff;
  
}




@media screen and (max-width: 736px) {
   .nav-menu {
    display: flex;
    flex-direction: column;
    width: 20em;
    max-width: 100%;
    top: 60px;
    right: -100%;
    padding: 10px;
    margin: 0;
    justify-content: flex-start;
    align-items: flex-start;
    transition: all 0.5s ease;
    color: black;
    cursor: default;
    z-index: 10002;
  }
    
  
  .logo-container {
    width: 100%;
    display: flex;
    text-align: center;
  }
  .logo {
    margin-left: -10px;
    height: 60px;
    width: 60px;
    padding: 10px;
   
  }
  .company-name {
    width: 100%;
    padding: 0.5em;
    font-size: 1.5em;
    line-height: 2px;
    
  }

  
  .nav-bar-btn {
    width: 100%;
    margin: 0 auto;
    padding: 10px;
  }
}
